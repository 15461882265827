.ContactPage {
    #header {
        @extend .solid;
        .bar {background:$white;}
        .logo-blue {display:none;}
        .logo-white {display:block;}

        .logo {
            display:none !important;
        }
    }
    overflow-x: hidden;

    .heading-wrap {
        padding:200px 20px 50px;
        text-align:center;

        .heading {
            font-size:40px;
            @include media-breakpoint-up(md) {
                font-size:80px;
            }
        }
        .subheading {
            color:$orange;
            font-size:24px;
        }
    }

    input[type=text], input[type=email], input[type=tel], input[type=number], input[type=date] {
        border: none;
        background-color: #F8F8F8;
        padding:33px 0 11px 18px;
        width: 100%;
        box-sizing: border-box;
        line-height: 20px;
        font-size: 16px;
        font-family: filson-pro, sans-serif;
        border-radius: 8px;
        color: #0e255a;
        transition: box-shadow 0.2s, transform .2s;
    }

    select[name="brand"] {
        background: #f8f8f8;
        border-radius: 8px;
        border: none;
        padding: 33px 0 11px 18px;
    }
}

.contact-grid-container {
    max-width:1160px;
    max-height:0px;
    overflow:hidden;
    transition:all 1s;
    opacity:0;
    &.active {
        max-height:3000px;
        opacity:1;
    }
}
.contact-grid {
    display:flex;
    flex-wrap:wrap;
    
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(3, 1fr);
    grid-column-gap: 0px;
    grid-row-gap: 0px;

    @include media-breakpoint-up(md) {
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: repeat(2, 1fr);
    }

    .contact-grid-item {
        color:$blue;
        font-size:20px;
        text-align: center;
        border: 1px solid #D9D9D9;
        font-weight: 700;
        background:#fff;
        transition: all .2s;
        padding:60px 10px;
        text-decoration:none;

        @include media-breakpoint-down(md) {
            &:first-child {
                border-radius: 20px 0px 0px 0px;
            }
            &:nth-child(2) {
                border-radius: 0px 20px 0px 0px;
            }
            &:nth-child(5) {
                border-radius: 0px 0px 0px 20px;
            }
            &:nth-child(6) {
                border-radius: 0px 0px 20px 0px;
            }
    
            &:first-child, &:nth-child(3) {
                border-right:none;
                border-bottom:none;
            }
            &:nth-child(2), &:nth-child(4) {
                border-bottom:none;
            }
            &:nth-child(5) {
                border-right:none;
            }
        }

        @include media-breakpoint-up(md) {
            padding:80px 10px;
            font-size:24px;

            &:first-child {
                border-radius: 20px 0px 0px 0px;
            }
            &:nth-child(3) {
                border-radius: 0px 20px 0px 0px;
            }
            &:nth-child(4) {
                border-radius: 0px 0px 0px 20px;
            }
            &:nth-child(6) {
                border-radius: 0px 0px 20px 0px;
            }

            &:first-child, &:nth-child(2) {
                border-right:none;
                border-bottom:none;
            }
            &:nth-child(3) {
                border-bottom:none;
            }
            &:nth-child(4), &:nth-child(5) {
                border-right:none;
            }    
        }

        p {
            max-width:280px;
            margin:0 auto 10px;
        }

        &:hover {
            background:#F8F8F8;
            color:$orange;
        }

        img {
            width:85px;
            margin-bottom:10px;
        }

    }
}

.faq-accordion {
    .question {
        font-size:24px;
        margin:0px;
    }
    .accordion-item {
        background:#F8F8F8;
        border:none;
        margin-bottom:12px;
        border-radius:20px;
    }
    .accordion-button {
        background:#F8F8F8;
        border-bottom:none;
        border-radius:20px !important;
        padding: 38px 40px;
        box-shadow:none !important;
    }
    .accordion-button:not(.collapsed) {
        background:#F8F8F8;
        color:$orange;
    }
    .accordion-body {
        border-radius:20px;
        padding: 0 40px 15px;
        p {
            font-size:20px;
        }
    }
    .accordion-collapse.show {
        .accordion-body {
            background: #F8F8F8;
        }
    }
    
}

.help-step-container {

    .help-step {
        border-radius:20px;
        background:#F8F8F8;
        color:$blue;
        padding:25px;
        border:1px solid #F8F8F8;
        margin-bottom:10px;
        color:$blue;
        text-decoration:none;
        max-height:103px;
        overflow: hidden;
        transition: max-height 1s;
        &.active {
            border:1px solid #D9D9D9;
            background:#fff;
            max-height:3500px;    
    
        }
    }
    .step-header {
        font-size:16px;
        font-weight:700;
        color:$blue;
        text-decoration:none;
    }
    .step-number {
        display:inline-block;
        width:70px;
        text-align:center;
        border-radius:25px;
        padding:15px 10px;
        background:#EFEFEF;
        margin-right:20px;
        font-size:14px;
    }

    .step-content {
        @include media-breakpoint-up(md) {
            padding:20px 90px;
        }
    }

    .search-wrap {
        position:relative;
        margin:20px 0 40px;
        .btn-search {
            position:absolute;
            display:flex;
            justify-content: center;
            align-items: center;
            top:50%;
            right:20px;
            transform:translateY(-50%);
            border-radius:50%;
            width:50px;
            height:50px;
            background:$orange;

        }
        .search-input {
            width:100%;
            border-radius:20px;
            background:#F8F8F8;
            border:none;
            height:76px;
            padding:10px 20px;
            color:$blue;
        }
    }
    .search-footer {
        text-align:center;
        margin:20px 0;
        .btn {
            width:100%;
            @include media-breakpoint-up(sm) {
                width:200px;
            }
        }
    }
    
}

#share-concern-view {
    .product-tiles, .brand-tiles {
        max-height:0px;
        opacity:0;
        overflow:hidden;
        transition:all 1s;
        &.active {
            max-height:1500px;
            opacity:1;
        }
        display: flex;
        flex-wrap: wrap;
        transform: translate(-25px, 25px);
        border-top: 1px solid #D9D9D9;
        width: calc(100% + 50px);        
    }
    .product-tile, .brand-tile {
        display: inline-flex;
        text-align: center;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        text-decoration: none;
        font-size: 20px;
        border-right:1px solid #D9D9D9;
        padding:20px 10px;
        color:$blue;
        transition: all .2s;

        &:last-child {
            border-right:none;
        }

        &:hover {
            background:#F8F8F8;
            color:$orange;
        }

        p {
            transform: translateY(-35px);
        }
    }


    .product-tiles {
        flex-wrap: wrap;
        @include media-breakpoint-up(lg) {
            flex-wrap: nowrap;
        }
    }

    .product-tile {
        width:50%;
        @include media-breakpoint-up(md) {
            width:25%;
            padding:35px 10px;
        }
        @include media-breakpoint-up(lg) {
            width:auto;
            flex:1;
        }
        img {
            mix-blend-mode: darken;
        }
    }

    .brand-tile {
        width:100%;
        @include media-breakpoint-up(md) {
            width:50%;
        }
        img {
            max-width:110px;
            @include media-breakpoint-up(md) {
                max-width:150px;
                padding:20px 0;
            }
        }
        &:nth-child(2) img {
            max-width:200px;
        }
    }

    .product-option {
        max-height:0px;
        opacity:0;
        overflow:hidden;
        transition:all 1s;
        text-align:center;
        &.active {
            max-height:1500px;
            opacity:1;
        }
    }

    .btn-product-option {
        font-size:24px;
        padding:20px;
        width:310px;
        border:1px solid;
        display: block;
        border-radius: 10px;
        text-decoration: none;
        margin:0 3px 12px;
        transition:all .2s;

        @include media-breakpoint-up(md) {
            width:400px;
        }

        &.orange {
            border-color:$orange;
            color:$orange;
            &:hover, &.active {
                background:$orange;
                color:#fff;
            }
        }
        &.blue {
            border-color:$blue;
            color:$blue;
            &:hover, &.active {
                background:$blue;
                color:#fff;
            }
        }
    }
    .btn {
        width:200px;
    }

    .product-code-heading {
        font-size:30px;
        margin-bottom:20px;
        font-weight:700;
        @include media-breakpoint-up(md) {
            font-size:50px;
            line-height:50px;
        }
    }

    .product-code-info {
        @include media-breakpoint-up(md) {
            max-width:380px;
        }
    }

    .carton-code-graphic {
        margin-top:20px;
        @include media-breakpoint-up(md) {
            margin-top:80px;
        }
    }
    #step-product-code {
        input {
            height:64px;
            padding:10px 20px;
            max-width:325px;
            text-transform:uppercase;
            color:$blue;
        }
    }

    .product-code-wrap {
        position:relative;
        .icon-valid, .icon-invalid {
            position:absolute;
            top:25px;
            left:290px;
        }

        .field-error, .icon-valid, .icon-invalid {display:none;}
        &.valid {
            .icon-valid {
                display:block;
            }
        }
        &.invalid {
            .icon-invalid {
                display:block;
            }
            input {
                border:1px solid $orange;
                color:$orange;
            }
            .field-error {
                display: block;
                color:$orange;
                font-size:14px;
                line-height:18px;
                margin:10px 0 0;
                max-width:300px;
            }
        }
    }
}


.white-spacer {
    min-height: 150px;
    z-index: 4;
    width: 100%;
}

/* Still need help section / Contact Form  */

.ContactTitleContent {
    align-content: center;
    text-align: center;
    justify-content: center;
    z-index: 2;
    margin-top: 60px;
    margin-bottom: 60px;

    @include media-breakpoint-up(md) {
    h1 {
        color: #1B1C47;
        text-align: center;
        font-size: 80px;
        font-style: normal;
        font-weight: 800;
        line-height: normal;
    }
    p {
        font-size: 20px;
    }
}
    .secondary-content {
        color: #FF5700;
    }
    #send-msg-btn {
        padding: 15px 30px;
        border-radius: 25px;
        background-color: #FF5700;
        color: #fff;
        transform: translateY(40px);
        transition: transform .5s .3s, opacity .5s .3s;
        border: none;

    }
}


.contact-form {
    .formContainer {

        position: relative;
        display: grid;
        // max-width: 555px;
        // border-radius: 20px;
        margin: 0 auto;
        column-gap: 19px;
        row-gap: 13px;
        grid-template-columns: 1fr 1fr;
        // box-shadow: 0px 40px 120px 0px rgba(27, 28, 71, 0.20);
        // padding: 25px;
        z-index: 2;

        @include media-breakpoint-up(md) {
            // padding: 50px;
        }
        

        h3 {
            grid-column: 1 / 3;
            color:#FF5700;
            display: flex;
            flex-direction: column;
            align-items: center;
            text-align: center;
        }
        .field-wrap {
            position:relative;
            label {
                position:absolute;
                left:18px;
                top:7px;
                color:$blue;
            }
        }
        .full-row {
            grid-column:1/3;
        }
        input {

            @media screen and (max-width: 614px) {
                grid-column: 1 / 3;
            }

            &::placeholder {
                color: rgba(14, 37, 90, 0.4);
            }

            // &:focus {
            //     outline: none;
            //     transform: translate(-6px, -6px);
            //     box-shadow: 6px 6px 0 0 #EFEFEF;

            // }
        }

        // & > input:not([name="newsletter"]), & > textarea {
        //     grid-column: 1 / span 2; // This will make all inputs (except newsletter) and textarea span two columns
        // }

        .topics {
            grid-column: 1 / 3;
            display: flex;
            flex-wrap: wrap;

            .label {
                font-size: 16px;
                line-height: 20px;
                flex-basis: 100%;
                color: #0e255a;
                margin: 0 0 14px 0;
            }

            label {
                display: inline-flex;
                position: relative;
                margin-right: 10px;
                margin-bottom: 10px;
                cursor: pointer;

                input {
                    appearance: none;
                    position: absolute;
                    margin: 0;

                    &:focus + span {
                        outline: 2px solid EFEFEF;
                        background-color: #FF5700;
                    }

                    &:checked + span {
                        background-color: #FF5700;
                        color: #fff;
                    }
                }

                span {
                    display: inline-block;
                    user-select: none;
                    padding: 10px 18px 10px 17px;
                    font-size: 12px;
                    line-height: 20px;
                    color: #1B1C47;
                    border: solid 1px #EFEFEF;
                    background-color: #EFEFEF;
                    border-radius: 25px;
                    transition: background-color 0.1s, color 0.1s;

                    @include media-breakpoint-up(md) {
                        font-size: 16px;

                        padding: 15px 23px 15px 23px;
                        line-height: 20px;
                    }
                }
            }
        }

        .upload {
            grid-column: 1 / 3;

            .label {
                font-size: 16px;
                line-height: 20px;
                flex-basis: 100%;
                color: #0e255a;
                margin: 0 0 14px 0;
            }

            label {
                display: block;
                text-align: center;
                font-size: 16px;
                line-height: 20px;
                width: 100%;
                box-sizing: border-box;
                padding: 19px 0 15px 0;
                color: #FF5700;
                border-radius: 8px;
                border: solid 1px #FF5700;
                transition: background-color 0.2s, color 0.2s;
                cursor: pointer;

                &:hover {
                    background-color: #FF5700;
                    color: #fff;
                }
            }

            input {
                display: none;
            }

            .file {
                text-align: center;
                margin: 14px 0 0 0;
                font-size: 15px;
                line-height: 20px;
                color: rgb(14, 37, 90, 0.45);
            }
        }

        textarea {
            grid-column: 1 / 3;
            font-family: filson-pro, sans-serif;
            padding: 32px 18px 20px;
            line-height: 20px;
            font-size: 16px;
            color: #0e255a;
            border-radius: 8px;
            resize: vertical;
            border: none;
            background-color: #F8F8F8;

            &:focus {
                outline: 2px solid #0e255a;
            }

            &::placeholder {
                font-family: filson-pro, sans-serif;
                line-height: 20px;
                font-size: 16px;
                color: rgba(14, 37, 90, 0.4);
            }
        }

        .newsletter {
            grid-column: 1 / 3;
            display: flex;
            align-items: center;


            input {
                appearance: none;
                display: inline-block;
                width: 25px;
                height: 25px;
                border-radius: 3px;
                margin: 0;
                padding:4px;


                &:not(:checked) {
                    border: 2px solid #FF5700;
                    background: transparent;
                }

                &:checked {
                    background: #FF5700;
                    border: none;

                    &::after {
                        display: inline-block;
                        width: 100%;
                        height: 100%;
                        background: #fff;
                        mask-repeat: no-repeat;
                        mask-size: contain;
                        mask-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' class='ionicon' viewBox='0 0 512 512'%3E%3Ctitle%3ECheckmark%3C/title%3E%3Cpath fill='none' stroke='currentColor' stroke-linecap='square' stroke-miterlimit='10' stroke-width='44' d='M416 128L192 384l-96-96'/%3E%3C/svg%3E");
                        content: "";
                    }
                }
            }

            span {
                font-size: 12px;
                margin-left: 12px;
                line-height: 1em;
                color: #0e255a;
                @include media-breakpoint-up(md) {
                    font-size: 16px;
                }
            }
        }

        .recaptcha {
            display: flex;
            grid-column: 1 / 3;

            span {
                position: relative;
            }
        }

        .contact-submit {
            width:210px;
        }

        .hidden {
            display: none;
        }
    }

	.success {
		position: relative;
		text-align: center;
		pointer-events: none;
        display: none;
        z-index: 1;
        background: #FFF;
        box-shadow: 0px 40px 120px 0px rgba(27, 28, 71, 0.20);
        background-image: url('../../images/Vector-success.svg');
        background-repeat: no-repeat;
        max-width: none;
        height: 647px;
        flex-shrink: 0;
        border-radius: 20px;
        margin: 10px auto;
        column-gap: 30px;
        row-gap: 10px;
        grid-template-columns: 1fr 1fr;
        padding: 18px;
        z-index: 2;
        @include media-breakpoint-up(sm) {
            max-width: 555px;
        }



		.success-image {
			position: relative;
			//opacity: 1;
			transition: opacity .3s .3s;
            margin-top: 50px;
            justify-content: center;
            align-items: center;
            display: flex;
            flex-direction: column;
            text-align: center;

			&::before {
				position: absolute;
				width: 100%;
				height: 100%;
				top: 0;
				left: 0;
				background-color: #faf1e0;
				mask-position: center;
				mask-repeat: no-repeat;
				mask-size: contain;
				mask-image: url(/_resources/themes/happy-egg/assets/blob-2.svg);
				content: "";
			}

			svg {
				position: relative;
                height: 100%;
                width: 100%;
			}
            .success-heading {
                margin: 14px 0;
                font-size: 50px;
                font-weight: 800;
                line-height: normal;
                color: #FF5700;
                //opacity: 0;
                transform: translateY(40px);
                transition: transform .5s .3s, opacity .5s .3s;

                @media screen and (max-width: 500px) {
                    font-size: 16vw;
                }
            }
            .success-message {
                font-family: filson-pro, sans-serif;
                margin: 0;
                font-size: 24px;
                line-height: 35ox;
                color: #0e255a;
                //opacity: 0;
                transform: translateY(40px);
                transition: transform .7s .3s, opacity .7s .3s;
            }
            #FAQ-btn {
                display: flex;
                margin-top: 80px;
                padding: 14px 15px 10px 15px;
                border-radius: 25px;
                background-color: #FF5700;
                color: #fff;
                width: 200px;
                transform: translateY(40px);
                transition: transform .5s .3s, opacity .5s .3s;
                border: none;
                justify-content: center;
                text-decoration: none;
            }
            .accent-egg-success {
                position: absolute;
                bottom: 20px;
                left: -35px;
                max-width: 80px;

                @include media-breakpoint-up(md) {
                    max-width: 140px;
                    bottom: -50px;
                    left: -25px;
                }
            }
            .falling-egg-success {
                position: absolute;
                top: 0px;
                right: -50px;
                transform: rotate(5.607deg);
                max-width: 100px;
                @include media-breakpoint-up(md) {
                    max-width: none;
                }

            }

		}


	}

	.error {
		grid-column: 1 / 3;
		text-align: center;
		font-size: 20px;
		line-height: 24px;
		margin: 0;
		color: red;
	}

    // .file:before {
    //     content:url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGhlaWdodD0iMTQiIHdpZHRoPSIxMiIgZmlsbD0iI2ZmZiIgdmlld0JveD0iMCAwIDQ0OCA1MTIiPjwhLS0hRm9udCBBd2Vzb21lIEZyZWUgNi41LjEgYnkgQGZvbnRhd2Vzb21lIC0gaHR0cHM6Ly9mb250YXdlc29tZS5jb20gTGljZW5zZSAtIGh0dHBzOi8vZm9udGF3ZXNvbWUuY29tL2xpY2Vuc2UvZnJlZSBDb3B5cmlnaHQgMjAyNCBGb250aWNvbnMsIEluYy4tLT48cGF0aCBkPSJNNDM4LjYgMTA1LjRjMTIuNSAxMi41IDEyLjUgMzIuOCAwIDQ1LjNsLTI1NiAyNTZjLTEyLjUgMTIuNS0zMi44IDEyLjUtNDUuMyAwbC0xMjgtMTI4Yy0xMi41LTEyLjUtMTIuNS0zMi44IDAtNDUuM3MzMi44LTEyLjUgNDUuMyAwTDE2MCAzMzguNyAzOTMuNCAxMDUuNGMxMi41LTEyLjUgMzIuOC0xMi41IDQ1LjMgMHoiLz48L3N2Zz4=");
    //     background: mediumseagreen;
    //     color: white;
    //     width: 20px;
    //     height: 20px;
    //     display: inline-block;
    //     border-radius: 50%;
    //     margin-right: 6px;
    //     padding-top: 2px;
    //     transform: translateY(3px);
    // }
}
